
import { defineComponent, ref, PropType, onMounted, watch, nextTick } from 'vue'
import SelectProductsDialog from '@/components/order/SelectProductsDialog.vue'
import { IProductListRes } from '@/api/products/type'
import { useWindowSize } from '@/use/useWindowSize'
import { useReplaceInput } from '@/use/useReplaceInput'
import { userCurrency } from '@/use/userCurrency'
import { useUserStore } from '@/pinia/user/user.pinia'
import { useRoute } from 'vue-router'
import { getProductNameWithTypes } from '@/utils/productName'

export default defineComponent({
	name: 'OrderProducts',
	components: { SelectProductsDialog },
	props:{
		products: {
			type: Array as PropType<IProductListRes[]>,
			default: () => []
		},
		isProductForm: {
			type: Boolean,
			default: false
		},
		isPlatformSize: {
			type: Boolean,
			default: false
		},
		isChat: {
			type: Boolean,
			default: false
		},
	},
	emits: [ 'update:products', 'update:isProductForm' ],
	setup(props, { emit }) {
		const { currencyMoneyDigits } = userCurrency()
		const userStore = useUserStore()
		const route = useRoute()
		const productList = ref<Array<IProductListRes>>([])
		const tempProductList = ref<Array<IProductListRes>>([])
		const isDialogProduct = ref(false)
		const selectProductsDialogDOM = ref<any>(null)
		const { mobileMode: isMobile } = useWindowSize()
		const { decimalNumberValueChecker, decimalValueChecker } = useReplaceInput()
		const isMoreThanMaxUnit = ref<boolean>(false)
		const isProductFormEnable = ref<boolean>(false)

		const onOpenDialog = () => {
			if(isDialogProduct.value) {
				isDialogProduct.value = false
			}
			isDialogProduct.value = true
			selectProductsDialogDOM.value?.onGetProducts()
		}

		const onSelectedProduct = (item: Array<IProductListRes>) => {
			const newProduct = item.map(e => {
				return {
					...e,
					totalUnit: e.unit,
					unit: 0,
				}
			})
			Array.prototype.push.apply(tempProductList.value, newProduct)
			const arrProductId = tempProductList.value.map(item => { return item._id })
			const duplicateArr = tempProductList.value.filter((item, idx) => { 
				return arrProductId.indexOf(item._id) !== idx 
			})
			const notDuplicate = tempProductList.value.filter((item, idx) => { 
				return arrProductId.indexOf(item._id) === idx 
			})

			const arrDuplicateId = duplicateArr.map(item => { return item._id })
			const newLoopArray = duplicateArr.filter((item, idx) => { 
				return arrDuplicateId.indexOf(item._id) === idx 
			})
			if(newLoopArray.length > 0) {
				notDuplicate.forEach(e => {
					newProduct.forEach(d => {
						if(e._id === d._id){
							e.unit = +(e.unit) + 1
						}
					})
				})
			}
			productList.value = notDuplicate
			emit('update:products', productList.value)
		}

		const assignValue = () => {
			if(props.products.length > 0){
				const productsArr = props.products.map(e => {
					return {
						...e.product,
						unit: +(e.unit),
						price: e.price,
						totalUnit: e.product?.unit,
					}
				})
				Array.prototype.push.apply(productList.value, productsArr)
				tempProductList.value = productList.value
				emit('update:products', productList.value)
			}
		}

		const deleteRow = (id: string) => {
			productList.value = productList.value.filter(e => {
				return e._id !== id
			})
			tempProductList.value = tempProductList.value.filter(e => {
				return e._id !== id
			})
			emit('update:products', productList.value)
		}

		const handleProductUnit = (unit: number, maxUnit: number) => {
			const intUnit = +(unit)
			if(intUnit <= maxUnit && intUnit >= 1) return intUnit
			if(!intUnit && intUnit === 0) return 0
			return maxUnit
		}

		watch(() => productList.value, (value) => emit('update:products', value))
		watch(() => isProductFormEnable.value, (value) => emit('update:isProductForm', value))

		onMounted(async() => {
			await nextTick()
			await assignValue()

			isProductFormEnable.value = !!productList.value.length
			const { enableInsertProductOrder } = userStore.permission?.settingConfig
			const { orderID } = route.query
			
			if (enableInsertProductOrder && !orderID) {
				isProductFormEnable.value = true
			}
			emit('update:isProductForm', isProductFormEnable.value)
		})

		return {
			productList,
			isDialogProduct,
			selectProductsDialogDOM,
			isMoreThanMaxUnit,
			tempProductList,
			isMobile,
			isProductFormEnable,
			getProductNameWithTypes,
			currencyMoneyDigits,
			onSelectedProduct,
			onOpenDialog,
			assignValue,
			deleteRow,
			decimalNumberValueChecker,
			handleProductUnit,
			decimalValueChecker
		}
	}
})
