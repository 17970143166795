import { v4 as uuidv4 } from 'uuid'
import jsQR from 'jsqr'
import axios from 'axios'

export const useVerifySlip = () => {
	const decodeQRCodeFromFacebookChat = async (
		dataWithReverse: any[],
		senderId?: string
	) => {
		let dataWithVerifyQrcode: any = []
		for (const chat of dataWithReverse) {
			let chatResult = chat
			if (chat?.type === 'ATTACHMENTS') {
				let attachmentResult: any = []
				if (senderId !== chat.senderId) {
					for (let attachment of chat?.attachments) {
						if (attachment.originalType === 'image/jpeg') {
							const response = await fetchUrl(attachment.payload?.url)
							if (response) {
								const { data,headers } = response
								const filename = headers['content-disposition']
								const filType = headers['content-type']
								const imageId = uuidv4()
								let imageName = imageId + '.' + filType.split('/')[1] // Default name if not found
								if (filename) {
									imageName = filename.split('filename=')[1].split('.')[0]
								}
								const metadata = {
									type: filType,
								}
								const file:File = new File([data], imageName, metadata)
								const reader = new FileReader()
								reader.readAsDataURL(file)
								attachment = {
									...attachment,
									payload: {
										url: attachment.payload?.url,
										file: file,
									},
								}
								try {
									const rawData = await decodeQRCode(file)
									attachment = { ...attachment, rawData, isQrCode: true }
								} catch (error) {
									attachment = { ...attachment, isQrCode: false }
								}
							}
						}
						const type = attachment.originalType.split('/')[0]
						attachment = {
							...attachment,
							type
						}
						attachmentResult.push(attachment)
					}
				}else{
					attachmentResult = chat.attachments.map((attachment: { originalType: string,type:string }) => {
						const type = attachment.originalType.split('/')[0]
						attachment = {
							...attachment,
							type
						}
						return {...attachment,type}
					})
				}
				chatResult = {
					...chatResult,attachments: attachmentResult 
				}
			}
			dataWithVerifyQrcode = [...dataWithVerifyQrcode, chatResult]
		}
		return { dataWithVerifyQrcode }
	}
	const decodeQRCode = async (file: File): Promise<string> => {
		if (file) {
			const fileResize = await resize(file)
			try {
				const reader = new FileReader()
				return new Promise((resolve, reject) => {
					reader.onload = async () => {
						const image: any = new Image()
						image.onload = async () => {
							const width = image.width
							const height = image.height
							// Create a canvas to get image data
							const canvas = document.createElement('canvas')
							const context = canvas.getContext('2d')
							canvas.width = width
							canvas.height = height
							context?.drawImage(image, 0, 0, width, height)
							const imageData = context?.getImageData(
								0,
								0,
								width,
								height
							)
							const uint8ClampedArray = new Uint8ClampedArray(
								imageData?.data.buffer as any
							)
							try {
								const result = jsQR(
									uint8ClampedArray,
									width,
									height
								)
								if (!result?.data) {
									reject()
								}
								resolve(result?.data as string)
							} catch (error) {
								reject(error)
							}
						}
						image.src = reader.result
					}
					reader.readAsDataURL(fileResize)
				})
			} catch (error) {
				// If an error occurs while reading the image file, reject the Promise with the error
				return Promise.reject('Error reading image file.')
			}
		}
		return Promise.reject('No file provided.')
	}
	const resize = (file: File): Promise<File> => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader()
			reader.onload = async () => {
				const image = new Image()
				image.onload = () => {
					// Convert centimeters to inches (1 inch = 2.54 cm)
					const desiredWidthInch = 40 / 2.54
					const dpi = 72
					const desiredWidthPx = Math.round(desiredWidthInch * dpi)
					const canvas = document.createElement('canvas')
					const context: any = canvas.getContext('2d')
					canvas.width = desiredWidthPx
					canvas.height =
						image.height * (desiredWidthPx / image.width)
					context.drawImage(
						image,
						0,
						0,
						desiredWidthPx,
						canvas.height
					)

					// Convert the canvas's data URL to a File object
					const dataURI = canvas.toDataURL()
					const fileResult = dataURLtoFile(dataURI, 'fileName')
					resolve(fileResult)
				}
				image.src = reader.result as string
			}
			reader.readAsDataURL(file)
		})
	}
	const dataURLtoFile = (dataURL: any, fileName: string) => {
		const arr = dataURL.split(',')
		const mime = arr[0].match(/:(.*?);/)[1]
		const bstr = atob(arr[1])
		let n = bstr.length
		const u8arr = new Uint8Array(n)

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n)
		}
		const blob = new Blob([u8arr], { type: mime })
		return new File([blob], fileName, { type: blob.type })
	}
	const fetchUrl = async(url:string) => {
		try {
			return await axios.get(url,{responseType:'blob'})
		} catch (error) {
			return null
		}
	}
	return { decodeQRCodeFromFacebookChat, resize, decodeQRCode, dataURLtoFile }
}
