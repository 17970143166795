import { AxiosResponse } from 'axios'
import axios from '../instance'
import { TransportObject } from './type'
import { CalculateObject } from '@/type/calculator'

export const getTransportlist = async (params: CalculateObject): Promise<TransportObject[]> => {
	try {
		const response = await axios.post('/rate-calculate', { ...params })
		return response.data
	} catch (error) {
		return Promise.reject(error)
	}
}


export const getBoxSize = async () => {
	try {
		const response = await axios.get('/rate-calculate/box-size')
		return response.data
	} catch (error) {
		return Promise.reject(error)
	}
}



export const getAddress = async () => {
	try {
		const response = await axios.get('/address/all')
		return response.data
	} catch (error) {
		return Promise.reject(error)
	}
}
