
enum TagEnum {
    danger = 'danger',
    warning = 'warning',
    primary = 'primary',
    success = 'success'
}

type TagType = {
    label: string,
    type: TagEnum,
}

export const tagData: TagType[] = [
	{
		label: 'ยังไม่ได้อ่าน',
		type: TagEnum.warning
	},
	{
		label: 'ส่งเลขพัสดุไม่สำเร็จ',
		type: TagEnum.danger
	},
	{
		label: 'รอตอบกลับ',
		type: TagEnum.primary
	},
	{
		label: 'รอชำระเงิน',
		type: TagEnum.warning
	},
	{
		label: 'สั่งซื้อแล้ว',
		type: TagEnum.success
	},
	{
		label: 'แจ้งปัญหา',
		type: TagEnum.danger
	}
]

export const tagMapper = (tagLabel: string): TagType|undefined => {
	return tagData.find(tag => tagLabel === tag.label)
}