import { onMounted, computed } from 'vue'
import { paymentStore } from '@/pinia/payment/payment.pinia'
import { getUserPermission } from '@/api/user/user.api'
import { useRoute, useRouter } from 'vue-router'
import { ORDER_PATH, ORDER_EDIT_PATH } from '@/constants/route'
import { ElNotification } from 'element-plus'
import { useShopStore } from '@/pinia/shop/shop.pinia'

export const useUserProfile = () => {
	const payment = paymentStore()
	const shopStore = useShopStore()
	const route = useRoute()
	const router = useRouter()
	const shopId = computed(() => shopStore.selectedShopId)
	const isReactNativeWebview = computed(() => window.ReactNativeWebView)
	const onFetchProfile = async () => {
		try {
			const { data } = await getUserPermission(shopId.value as string)
			if (data) {
				payment.isBlockBillPayment = data.isBlockBillPayment
			}
		} catch (error) {
			ElNotification({
				title: 'แจ้งเตือน',
				message: 'ไม่สามารถดึงข้อมูลได้ กรุณาติดต่อแอดมิน',
				type: 'warning',
				showClose: !isReactNativeWebview.value
			})
		}
	}
	onMounted(async () => {
		await onFetchProfile()
		if (payment.isBlockBillPayment && route.path === ORDER_EDIT_PATH) {
			router.push({ path: ORDER_PATH })
		}
	})

	return {
		onFetchProfile,
	}
}
