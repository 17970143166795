import {
	fetchPageSetting,
	getInboxList,
	getMessageByInboxId,
	getOrderHistoryById,
	updateUnreadInbox,
} from '@/api/chat/chat.api'
import {
	GetInboxParams,
	GetMessageByIdParams,
	GetOrderHistoryByIdParams,
} from '@/api/chat/chat.type'
import { defineStore } from 'pinia'
import dayjs from '@/utils/dayjs'
import { useVerifySlip } from '@/use/useVerifySlip'
import { useCourierStore } from '../courier/courier.pinia'

export const useChatStore = defineStore('chat', {
	state: () =>
		({
			isMobile: false,
			inboxList: [],
			inboxListTotals: 0,
			inboxUnreadTotal: 0,
			inboxAfterToken: null,
			isFetchingMessageList: false,
			messageList: [],
			messagePagination: {
				limit: 20,
				afterToken: null,
			},
			display: {
				// All default display true
				clientChatListTab: true,
				messageTab: true,
				orderTab: true,
			},
			orderTabType: 'history',
			selectedInbox: null,
			selectedAddress: '',
			getOrderHistoryList: [],
			getOrderHistoryTotals: {
				offset: 1,
				limit: 10,
				totals: 0,
			},
			setting: null,

			// to prevent race condition when get inbox page
			// we need to store lastConversionId and if the response
			lastPageId: null,
			lastConversationId: null,
			lastOrderHistoryByInboxId: null,
			// slip
			selectedSlip: null,
		} as any),
	getters: {
		inboxListSortByDate(state) {
			return state.inboxList
				.map((inbox: any) => {
					return {
						...inbox,
						dayFromNow: dayjs(inbox.lastUpdatedTime)
							.locale('th')
							.fromNow(),
					}
				})
				.sort(
					(a: any, b: any) =>
						dayjs(b.lastUpdatedTime).unix() -
						dayjs(a.lastUpdatedTime).unix()
				)
		},
	},
	actions: {
		setAllDisplayNone() {
			this.display = {
				clientChatListTab: false,
				messageTab: false,
				orderTab: false,
			}
		},
		showClientChatTab() {
			if (!this.isMobile) {
				return
			}
			this.setAllDisplayNone()
			this.display.clientChatListTab = true
		},
		hideClientChatTab() {
			if (!this.isMobile) {
				return
			}
			this.setAllDisplayNone()
			this.display.clientChatListTab = false
		},
		showMessageTab() {
			if (!this.isMobile) {
				return
			}
			this.setAllDisplayNone()
			this.display.messageTab = true
		},
		hideMessageTab() {
			if (!this.isMobile) {
				return
			}
			this.setAllDisplayNone()
			this.display.messageTab = false
		},
		showOrderTab(type = 'history') {
			if (!this.isMobile) {
				return
			}
			this.setAllDisplayNone()
			this.display.orderTab = true
			this.orderTabType = type

		},
		hideOrderTab() {
			if (!this.isMobile) {
				return
			}
			this.setAllDisplayNone()
			this.display.orderTab = false
		},

		// Api call
		async actionGetInboxList(params: Omit<GetInboxParams, 'afterToken'>) {
			try {
				this.lastPageId = params.pageId
				const { data: response } = await getInboxList({
					...params,
					afterToken: this.inboxAfterToken,
				})
				if (this.lastPageId !== params.pageId) return
				// filter เอาข้อความที่มีอยู่แล้วออก ก่อน เช่น มีคนทักมานอกเหนือจาก inbox ที่ดึงมา socket จะเอามาแปะให้เลย
				// แต่หากมีการ fetch ต่อ แล้วไปได้ข้อความที่ซ้ำที่อยู่หลังๆมา ต้องเอาออก
				const existIds = this.inboxList.map(
					(each: any) => each.senderId
				)
				const responseWithoutDupSenderIds = response.data.filter(
					(inbox: any) => !existIds.includes(inbox.senderId)
				)
				this.inboxList = [
					...this.inboxList,
					...responseWithoutDupSenderIds,
				]
				this.inboxAfterToken = response.afterToken
				this.inboxUnreadTotal = response.unreadTotal
			} catch (error) {
				return Promise.reject(error)
			}
		},
		async actionGetInboxListByConversationId(
			params: Omit<GetInboxParams, 'afterToken'>
		) {
			try {
				const { data: response } = await getInboxList({
					...params,
					afterToken: this.inboxAfterToken,
					_conversationId: params._conversationId,
				})
				this.inboxList = [...this.inboxList, ...response.data]
				this.inboxAfterToken = response.afterToken
				this.inboxUnreadTotal = response?.unreadTotal
			} catch (error) {
				return Promise.reject(error)
			}
		},
		// Api call with search hack for no duplicate inbixList
		async actionGetInboxListWithSearch(
			params: Omit<GetInboxParams, 'afterToken'>
		) {
			try {
				const { data: response } = await getInboxList({
					...params,
					afterToken: this.inboxAfterToken,
				})
				this.inboxList = response.data
				this.inboxAfterToken = response.afterToken
				this.inboxUnreadTotal = response?.unreadTotal
			} catch (error) {
				return Promise.reject(error)
			}
		},

		async actionGetMessageByInboxId(
			params: Omit<GetMessageByIdParams, 'afterToken'>
		) {
			const { decodeQRCodeFromFacebookChat } = useVerifySlip()
			this.isFetchingMessageList = true
			// set last conversionId for prevent race condition
			this.lastConversationId = params._conversationId
			try {
				const { data: response } = await getMessageByInboxId({
					...params,
					afterToken: this.messagePagination.afterToken,
				})
				// do not need to set if current conversion not allow
				if (this.lastConversationId !== params._conversationId) return

				const dataWithReverse = response.data.reverse() // last message will be position 0
				const { dataWithVerifyQrcode } =
					await decodeQRCodeFromFacebookChat(
						dataWithReverse,
						params.senderId
					)

				this.messageList.unshift(...dataWithVerifyQrcode)
				this.messagePagination.afterToken = response.afterToken
				// this.messagePagination.totals = response.pagination?.totals
			} catch (error) {
				return Promise.reject(error)
			} finally {
				this.isFetchingMessageList = false
			}
		},
		onSelectedAddress(address: string, type: string) {
			this.selectedAddress = address
			this.orderTabType = type
		},
		async actionOrderHistoryByInboxId(params: GetOrderHistoryByIdParams) {
			this.isFetchingMessageList = true
			try {
				this.lastOrderHistoryByInboxId = params.inboxId
				const { data: response } = await getOrderHistoryById(params)
				
				if (this.lastOrderHistoryByInboxId !== params.inboxId) return
				const newData = response.data
				this.getOrderHistoryList.push(...newData)
				this.getOrderHistoryTotals = response.pagination?.pages
			} catch (error) {
				return Promise.reject(error)
			} finally {
				this.isFetchingMessageList = false
			}
		},
		async actionUpdateUnreadMessage(inbox: any) {
			try {
				// no need to await
				inbox.isUnread = false
				await updateUnreadInbox(inbox?.inboxId)
			} catch (error) {
				return Promise.reject(error)
			}
		},
		async actionGetSettingConfig(pageId: string) {
			try {
				const {
					data: {
						settingConfig: { syncChat },
					},
				} = await fetchPageSetting(pageId as string)
				this.setting = syncChat
			} catch (error) {
				return Promise.reject(error)
			}
		},
		updateTagInInboxList(inboxId: string, tags: string[]) {
			this.inboxList = this.inboxList.map((each: any) => {
				if (each.inboxId === inboxId) {
					return {
						...each,
						tags,
					}
				}
				return each
			})
		},
		onSelectedSlip(slip: any, type: string) {
			this.orderTabType = type
			this.selectedSlip = {
				url: slip.payload.file,
				rawData: slip.rawData,
			}
		},
	},
})
