export const bkkSurroundZipcode = [
	10600,
	10510,
	10110,
	10260,
	10230,
	10900,
	10150,
	10210,
	10400,
	10300,
	10303,
	10170,
	10140,
	10700,
	10240,
	10250,
	10120,
	10800,
	10500,
	10220,
	10160,
	10330,
	10100,
	10200,
	10520,
	10310,
	10530,

	11130,
	11110,
	11140,
	11120,
	11000,
	11150,

	12120,
	12110,
	12130,
	12140,
	12150,
	12160,
	12170,
	12000,

	10550,
	10560,
	10540,
	10570,
	10130,
	10290,
	10280,
	10270
]