import { useShopStore } from '@/pinia/shop/shop.pinia'
import { useUserStore } from '@/pinia/user/user.pinia'

export const useFetchDataOrder = () => {
	const shopStore = useShopStore()
	const userStore = useUserStore()

	const onFetchUserData = async () => {
		const { selectedShopId } = shopStore
		await userStore.getUserRoleInShopId({
			shopId: selectedShopId as string,
		})
	}

	const textSpecialZone = (
		isRemoteZone: boolean,
		isTravelZone: boolean,
	) => {
		if (isRemoteZone && isTravelZone) {
			return '* ค่าจัดส่งเพิ่มเติม (เนื่องจากเป็นพื้นที่ห่างไกล/ท่องเที่ยว)'
		} else if (isRemoteZone) {
			return '* ค่าจัดส่งเพิ่มเติม (เนื่องจากเป็นพื้นที่ห่างไกล)'
		}
		return '* ค่าจัดส่งเพิ่มเติม (เนื่องจากเป็นพื้นที่ท่องเที่ยว)'
	}
	return {
		onFetchUserData,
		textSpecialZone,
	}
}
