import { onMounted, onUnmounted } from 'vue'

export const useRemoveReadOnly = (inputId: string) => {
	const addEventListenerReadOnly = () => {
		removeReadOnlyFromElement(inputId)
	}
	const removeEventListenerReadOnly = () => {
		const element = document.getElementById(inputId)
		if(element){
			element.setAttribute('readonly', 'true')
		}
	}

	const removeReadOnly = () => {
		removeReadOnlyFromElement(inputId)
	}

	const removeReadOnlyFromElement = (elemId: string) => {
		const element = document.getElementById(elemId)
        element?.addEventListener('touchstart', (e: Event) => {
        	element.removeAttribute('readonly')
        })
        element?.addEventListener('focus', (e: Event) => {
        	element.removeAttribute('readonly')
        })
	}

	onMounted(addEventListenerReadOnly)

	onUnmounted(removeEventListenerReadOnly)

	return {
		addEventListenerReadOnly,
		removeEventListenerReadOnly,
		removeReadOnly,
	}
}