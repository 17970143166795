import { defineStore } from 'pinia'
import { State, FindAddressParams, AddressFindType } from '@/pinia/address/types'
import { AddressI, ProvinceI } from '@/api/address/address.api'
import { getAddressVersion, getAllProvince } from '@/api/address/addressSystem'
import { getUniqueListBy } from '@/utils/common'

export const useAddressStore = defineStore('address', {
	persist: true,
	state: (): State => ({
		version: null,
		provinceList: []
	}),
	getters: {
		findAllName: (state: State) => (params: FindAddressParams): AddressFindType|undefined => {
			const findAddressWithDetail = state.provinceList.find(addr => {
				return addr.provinceCode === params.provinceCode && addr.districtCode === params.districtCode && addr.subDistrictCode === params.subDistrictCode
			})
			if (!findAddressWithDetail) {
				return undefined
			}
    
			return {
				provinceName: findAddressWithDetail.provinceName,
				districtName: findAddressWithDetail.districtName,
				subDistrictName: findAddressWithDetail.subDistrictName,
			}
		},
		province(state: State): ProvinceI[] {
			const provinceOnly = getUniqueListBy(state.provinceList, 'provinceCode')
			return provinceOnly as ProvinceI[]
		},
	},
	actions: {
		// old mutation
		updateProvinceList(provinceList: AddressI[]) {
			this.provinceList = provinceList
		},
		updateAddressVersion(version: number) {
			this.version = version
		},
		// old actions
		async getAllProvinceStore() {
			try {
				const { data: addressVersion } = await getAddressVersion()
				if (!this.version || this.version !== addressVersion) {
					const province = await getAllProvince()
					this.updateProvinceList(province)
					this.updateAddressVersion(addressVersion) // update version
				}
			} catch (error) {
				return Promise.reject(error)
			}
		}
	}
})