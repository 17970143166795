export enum SyncChatTag {
	UNSENT_TRACKING_NOTIFY = 'ส่งเลขพัสดุไม่สำเร็จ',
	UNREAD = 'ยังไม่ได้อ่าน'
}

export type GetInboxParams = {
    pageId: string
    limit: number,
    afterToken?: string,
    search?: string,
    tags?: SyncChatTag[],
    isFindByConversationId?: boolean,
    _conversationId?: string,
}

export type GetMessageByIdParams = {
    _conversationId: string,
    pageId: string
    limit: number,
    afterToken?: string,
    senderId?: string,
}

export type GetOrderHistoryByIdParams = {
    inboxId: string
    offset: number
    limit: number
}

export enum SendFacebookMessageError {
    SEND_MESSAGE_EXPIRED = 'SEND_MESSAGE_EXPIRED',
    SEND_MESSAGE_ERROR = 'SEND_MESSAGE_ERROR'
}
